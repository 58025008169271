import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout-template'
import styles from './index.module.css'


class RootIndex extends React.Component {
  render() {
    const siteTitle = "Right On Software";
    
    return (
      <Layout location={this.props.location} >
        <div>
          <Helmet title={siteTitle} />
            <div className={styles.homeWrapper}>
              <h1>Right On Software</h1>
          </div>
        </div>
      </Layout>
    )
  }
}

export default RootIndex